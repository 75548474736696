import React, { Component } from 'react';

import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Header from '../components/Header';
import Footer from '../components/Footer';

import './blog.scss';

class Blog extends Component {
  render() {
    return (
      <div className="container">
        <Header />
        <div className="blog-list-container">{this.renderBlogList()}</div>
        <Footer />
      </div>
    );
  }

  renderBlogList = () => {
    const { data } = this.props;
    const posts = data.allMarkdownRemark.edges;

    if (posts.length === 0) {
      return this.renderEmptyBlog();
    }

    return <>{posts.map(this.renderBlogItem)}</>;
  };

  renderEmptyBlog = () => {
    return (
      <div className="empty-blog-section">
        <h4>Oops! There&apos;s nothing here! Something cool is coming here!</h4>
      </div>
    );
  };

  renderBlogItem = ({ node }) => {
    const { fields, excerpt, frontmatter, timeToRead } = node;
    const { date, featuredImage, title, description } = frontmatter;
    const blogMeta = `${date} · ${timeToRead} min read`;
    return (
      <Link
        style={{ boxShadow: `none`, textDecoration: 'none', color: 'black' }}
        to={node.fields.slug}
      >
        <div className="d-md-flex flex-md-row blog-item-container">
          <div className="col-md-6">
            <Image className="blog-item-image" fluid={featuredImage.childImageSharp.fluid} />
          </div>
          <div className="col-md-1" />
          <div className="col-md-5 d-flex flex-column justify-content-center">
            <h2>{title || fields.slug}</h2>
            <p>{description || excerpt}</p>
            <div className="blog-item-meta">{blogMeta}</div>
          </div>
        </div>
      </Link>
    );
  };
}

Blog.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  }).isRequired
};

export default Blog;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          excerpt
          timeToRead
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            type
            featuredImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 1240) {
                  src
                  aspectRatio
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
